import React from "react"
import Link from "../link"
import Section from "../section"
import Column from "../column"
import TextBlock from "../text-block"
import Card from "../card"
import BrandName from "../brand-name"

import IconTraining from "../../images/icon-training.svg"
import IconHardware from "../../images/icon-hardware.svg"
import IconSoftware from "../../images/icon-software.svg"

export default ({ classes, id, text, children }) => (
    <Section classes={classes} id={id}>
        <div className="container">
            <Column classes={['intro']}>
                <TextBlock title="Professionelle und effiziente {b}TI‑Anbindung.{/b}">
                    <p>Um in den Praxen eine einwandfreie Installationen zu garantieren bietet <BrandName /> ein umfassendes Komplettpaket.</p>
                </TextBlock>
            </Column>
        </div>
        <div className="container">
            <div className="cards">
                <Column classes={['cards__column']}>
                    <Card title="Schulung" link={{ title: 'Zum Installationsguide', url: 'https://setup.meinzugangsdienst.de', external: true }} image={<img src={IconTraining} alt="Grafik: Schulung" width="128" height="128" />} classes={['card--with-icon']}>
                        <p>DVOs werden im Vorfeld praxisnah an der verwendeten Hardware geschult und wir geben hilfreiche Tools wie das <Link to="https://setup.meinzugangsdienst.de/supportkit" external={true}>Support-Kit</Link> und den <strong>Installationsguide</strong> an die Hand.</p>
                    </Card>
                </Column>
                <Column classes={['cards__column']}>
                    <Card title="One-Stop-Hardware" link={{ title: 'Mehr zur Hardware', url: '/dvo/#hardware' }} image={<img src={IconHardware} alt="Grafik: Hardware" width="128" height="128" />} classes={['card--with-icon']}>
                        <p>Die Hardwarekomponenten sind perfekt aufeinander abgestimmt. Optimierte Logistikabläufe und die Lieferung aller Komponenten aus einer Hand ermöglichen eine Installation ganz ohne Hindernisse oder zeitliche Verzögerungen.</p>
                    </Card>
                </Column>
                <Column classes={['cards__column']}>
                    <Card title="Software" link={{ title: 'Mehr zur Software', url: '/dvo/#software' }} image={<img src={IconSoftware} alt="Grafik: Software" width="128" height="128" />} classes={['card--with-icon']}>
                        <p>Die <BrandName /> Konnektor-Software ist äußerst leistungsfähig. Behalten Sie als DVO alle Mandanten im Blick und profitieren Sie von Fernwartungs-Möglichkeiten.</p>
                    </Card>
                </Column>
            </div>
        </div>
    </Section>
)
