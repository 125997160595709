import React from "react"

import Section from "../section"
import Column from "../column"
import TextBlock from "../text-block"
import Card from "../card"
import {default as Image} from "../images/interface"

export default ({ classes, id, text, children }) => (
    <Section classes={classes} id={id}>
        <div className="container vcenter text-large">
            <Column classes={['column--image']}>
                <Image />
            </Column>
            <Column classes={['column--copy']}>
                {children}
                <TextBlock title="Ein Interface, {b}alles im Blick.{/b}" classes={['text-block--limited']}>
                    <p>Mit dem browserbasierten Konnektor-Interface ist die Konfiguration der Hardware vor Ort beinahe ein Kinderspiel. Die Wartung, Problemanalyse und Aktualisierung von Konnektor und Karten-Terminals erfolgt über den sicheren Fernzugriff.</p>
                </TextBlock>
            </Column>
        </div>
        <div className="container container--spacing">
            <div className="cards cards--two">
                <Column classes={['cards__column']}>
                    <Card title="Installationsguide" link={{ title: 'Zum Installationsguide', url: 'https://setup.meinzugangsdienst.de', external: true }} classes={['card--inline']}>
                        <p>Der Installationsguide führt Sie Schrift für Schritt durch die Konfiguration des Konnektors und liefert Hinweise und Tipps zur Installation der Hardware sowie aller damit verbundenen Einstellungen. Als Web-App ist der Leitfaden auf Smartphone oder Tablet sogar offline verfügbar.</p>
                    </Card>
                </Column>
                <Column classes={['cards__column']}>
                    <Card title="Support-Kit" link={{ title: 'Support-Kit laden', url: 'https://setup.meinzugangsdienst.de/supportkit', external: true }} classes={['card--inline']}>
                        <p>Das Support-Kit ermöglicht die Überwachung der verbundenen Konnektoren, die Fehleranalyse oder die Durchführung von Software-Updates per Fernzugriff. Auch bei der Installation vor Ort unterstützt die Software mit starken Funktionen, beispielsweise dem automatischen Auffinden verbundener Konnektoren im Praxisnetzwerk.</p>
                    </Card>
                </Column>
            </div>
        </div>
    </Section>
)
