import React from "react"

import Layout from "../components/layouts/default"
import SEO from "../components/global/seo"
import Header from "../components/header"
import BrandName from "../components/brand-name"
import AvatarDVO from "../images/avatar-dvo.svg"

import SectionFeatures from "../components/sections/features-dvo"
import SectionKonnektor from "../components/sections/konnektor"
import SectionTerminal from "../components/sections/terminal"
import SectionSoftware from "../components/sections/software"

export default () => (
    <Layout className="wrapper--dark">
        <SEO title="Telematik für Dienstleister vor Ort – Jetzt Angebot anfordern" description="Mein Zugangsdienst bietet ein Komplettpaket aus Schulung, Hardware und Software für die reibungslose Anbindung an die Telematikinfrastruktur (TI)." />
        <article>
            <Header title="Gemacht für {b}Dienstleister vor Ort.{/b}" image={<img src={AvatarDVO} alt="DVO" width="128" height="128" />} classes={['section dark']}>
                <p>Als Dienstleister vor Ort sind Sie der Ansprechpartner, wenn es für Ärzte und Praxen um das Thema Telematikinfrastruktur (TI) geht. <BrandName /> stellt Ihnen dazu alle erforderlichen Werkzeuge zur Verfügung, die Sie von der ersten Beratung bis zur reibungslosen Anbindung einer Praxis an die TI benötigen.</p>
            </Header>
            <SectionFeatures classes={['section--alternate section--masked']} />
            <SectionKonnektor classes={['text-large section--vcenter section--alternate section--default section--image-bleed']} id="hardware">
                <h1 className="tiny">Hardware</h1>
            </SectionKonnektor>
            <SectionTerminal classes={['text-large section--vcenter section--alternate section--default section--image-bleed']} />
            <SectionSoftware classes={['section--masked section--default section--image-bleed section--alternate-2']} id="software" />
        </article>
    </Layout>
)
